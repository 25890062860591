import { ActivityGroup } from "./ActivityGroup";
import { BaseEntity } from "./BaseEntity";
import { Client } from "./Client";
import { EmissionFactor } from "./EmissionFactor";
import { Scope } from "./Scope";
import { SubScopeGroup } from "./SubscopeGroup";
import { Tenant } from "./Tenant";
import { Variable } from "./Variable";

export class Activity extends BaseEntity{
    subScopeId!: number;
    groupId!: number;
    subScopeGroup!: SubScopeGroup;
    tenantId!: number;  
    tenant!: Tenant;
    name!: string;
    isEmission!: boolean;
    period!: string; //Posible ENUM
    unit!: string;
    emissionFactorId!: number;
    emissionFactor!:EmissionFactor;
    description!: string;
    isVisibleToUser!: boolean;
    variableId? : number;
    variable? : Variable;
    activityGroupId? : number;
    activityGroup? : ActivityGroup;
}